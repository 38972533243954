<template>
    <router-link
        class="group card padding-medium depth-1"
        :to="{
            name: 'group',
            params: { groupId: 1, group: group, groupName: group.name }
        }"
    >
        <div class="text pbottom-small">
            <h3>{{ group.name }}</h3>
            <div class="meta">
                {{ group.members }} Members – {{ group.type }}
            </div>
        </div>
        <div class="avatar-stack">
            <div
                v-for="(ava, i) in avatars"
                :key="ava"
                class="avatar"
                :style="`z-index: ${avatars.length - i}`"
            >
                <img
                    :src="ava"
                    alt=""
                />
            </div>
            <div class="avatar">
                <span>+11</span>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: {
        group: {
            type: Object,
            default: () => {
                return { name: "Group", members: 5, type: "Group" };
            }
        },
        avatars: {
            type: Array,

            default: () => {
                return [
                    "https://randomuser.me/api/portraits/women/44.jpg",
                    "https://randomuser.me/api/portraits/men/97.jpg",
                    "https://randomuser.me/api/portraits/women/8.jpg"
                ];
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .group {
        border: 3px solid var(--lowest-contrast, #{$off-white});
        text-decoration: none;
        min-height: 120px;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        &:hover {
            transform: translateY(-4px);
            box-shadow: $depth-2;
        }
    }
    h3 {
        margin-bottom: 0;
    }
    .avatar-stack {
        .avatar {
            position: relative;
            &:not(:first-child) {
                margin: 0 0 0 -0.6rem;
            }
        }
    }
</style>
