<template>
    <div>
        <PostIt>{{ $t('example_content' ) }}</PostIt>
        <h2>{{ $t("groups") }}</h2>
        <article>
            <h3>{{ $t("teams") }}</h3>
            <div class="group-grid pbottom-medium">
                <GroupTile
                    v-for="(group, i) in teams"
                    :key="group.name"
                    :group="group"
                />
            </div>
        </article>
        <article>
            <h3>{{ $t("locations") }}</h3>
            <div class="group-grid pbottom-medium">
                <GroupTile
                    v-for="(group, i) in locations"
                    :key="group.name"
                    :group="group"
                />
            </div>
        </article>
        <article>
            <h3>{{ $t("interests") }}</h3>
            <div class="group-grid pbottom-medium">
                <GroupTile
                    v-for="(group, i) in interests"
                    :key="group.name"
                    :group="group"
                />
            </div>
        </article>
    </div>
</template>

<script>
import GroupTile from "@web/components/GroupTile";

export default {
    components: {
        GroupTile
    },
    data() {
        return {
            teams: [
                {
                    name: "Team Franklin",
                    description:
                            "The team around Nate is responsible for most of the design of our apparel and prints. ",
                    members: 16,
                    type: "Team"
                },
                {
                    name: "Team Marketing",
                    description: "The gorilla marketing Team!",
                    members: 16,
                    type: "Team"
                },
                {
                    name: "Team Zeil Store",
                    description: "",
                    members: 5,
                    type: "Team"
                },
                {
                    name: "Team Brick Lane",
                    description: "",
                    members: 5,
                    type: "Team"
                },
                {
                    name: "Logistics",
                    description: "",
                    members: 5,
                    type: "Team"
                },
                {
                    name: "Team Sparko",
                    description: "",
                    members: 5,
                    type: "Team"
                }
            ],
            locations: [
                {
                    name: "London",
                    members: 16,
                    type: "Location"
                },
                {
                    name: "Frankfurt",
                    members: 16,
                    type: "Location"
                },
                {
                    name: "Remote",
                    description: "Los Remotos.",
                    members: 2,
                    type: "Location"
                }
            ],
            interests: [
                {
                    name: "Designers",
                    members: 7,
                    type: "Topic"
                },
                {
                    name: "Coffee Lovers",
                    members: 7,
                    type: "Topic"
                },
                {
                    name: "Fashion Design",
                    members: 7,
                    type: "Topic"
                },
                {
                    name: "Finance",
                    members: 7,
                    type: "Topic"
                },
                {
                    name: "Office Managers",
                    members: 7,
                    type: "Topic"
                },
                {
                    name: "Food Lovers",
                    members: 7,
                    type: "Topic"
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
    .group-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .group {
            flex: 0 1 240px;
            margin: 0 1.5rem 1.5rem 0;

            user-select: none;
        }
    }
</style>
